exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/ContentPage.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-events-category-get-js": () => import("./../../../src/templates/EventsCategoryGet.js" /* webpackChunkName: "component---src-templates-events-category-get-js" */),
  "component---src-templates-events-category-list-js": () => import("./../../../src/templates/EventsCategoryList.js" /* webpackChunkName: "component---src-templates-events-category-list-js" */),
  "component---src-templates-press-release-get-js": () => import("./../../../src/templates/PressReleaseGet.js" /* webpackChunkName: "component---src-templates-press-release-get-js" */),
  "component---src-templates-press-release-list-js": () => import("./../../../src/templates/PressReleaseList.js" /* webpackChunkName: "component---src-templates-press-release-list-js" */)
}

