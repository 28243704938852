module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T4DBKH3F","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Keep Walton County Beautiful","background_color":"#52805D","theme_color":"#52805D","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"628ff7b9e8e35b389b0905b936b2dccb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-source-zms/plugins/gatsby-source-zms/gatsby-browser.js'),
      options: {"plugins":[],"baseURL":"https://www.keepwaltoncountybeautiful.org","models":["Content.Page","Content.Menu","SmartTags.FeaturedContent","SmartTags.StatCards","SmartTags.IconCards","SmartTags.ContentBlock","System.File","Events.Category","Events.Event","Press.Release"],"pages":[{"type":"ContentPage","component":"/opt/atlassian/pipelines/agent/build/src/templates/ContentPage.js"},{"type":"EventsCategory","pagePattern":["/events/"],"component":"/opt/atlassian/pipelines/agent/build/src/templates/EventsCategoryList.js","pageSize":1,"overshoot":0,"listing":true},{"type":"EventsCategory","path":"/events/$permalink/","component":"/opt/atlassian/pipelines/agent/build/src/templates/EventsCategoryGet.js"},{"type":"EventsEvent","component":"/opt/atlassian/pipelines/agent/build/src/templates/EventsEventGet.js","path":"/event/$permalink/"},{"type":"PressRelease","pagePattern":["/press/","/press/page-$page/"],"component":"/opt/atlassian/pipelines/agent/build/src/templates/PressReleaseList.js","pageSize":12,"overshoot":2,"listing":true},{"type":"PressRelease","component":"/opt/atlassian/pipelines/agent/build/src/templates/PressReleaseGet.js","path":"/press/$permalink/"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
